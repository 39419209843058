<template>
  <b-container class="myProfile">
    <loading
      :active.sync="isLoading"
      :color="'#408521'"
      :is-full-page="false"
    ></loading>

    <b-row>
      <b-col md="12">
        <h3>Moj profil</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <article class="post-area single-blog mt-4 p-3">
          <div class="about-me">
            <div class="single-about">
              <b-row>
                <b-col :md="editMode ? 6 : 12" sm="12" xs="12">
                  <b-row>
                    <b-col md="12">
                      <div
                        class="about-us-img float-none float-md-left pr-0 pb-0 text-center mr-5 ml-2"
                      >
                        <div
                          class="rounded-circle text-center overflow-hidden"
                          style="width: 240px; height: 240px;  display: flex; align-content: center; align-items: center; text-align: center; align-self: center;"
                        >
                          <b-img
                            v-if="user.profileImg || photoPreview"
                            :src="photoPreview ? photoPreview : user.profileImg"
                            :alt="user.displayName"
                            style="height: 240px; width: 240px; max-width: initial; margin: 0px auto; text-align: center; display: flex; align-content: center; align-items: center; object-fit: cover;"
                          />
                          <b-img
                            v-if="!user.profileImg"
                            :src="require('@/assets/images/user-krogec.svg')"
                            :alt="user.displayName"
                            style="height: 240px; width: 240px; max-width: initial; margin: 0px auto; text-align: center; display: flex; align-content: center; align-items: center; object-fit: cover;"
                          />
                        </div>
                      </div>
                      <div class="about-us-text">
                        <h3 class="text-uppercase">
                          {{ user.firstName + " " + user.lastName }}
                        </h3>
                        <h5>{{ user.displayName }}</h5>
                        <p>
                          {{ user.bio }}
                        </p>
                      </div>

                      <b-button
                        v-if="!editMode"
                        variant="primary"
                        class="mx-1"
                        @click="
                          () => {
                            enableEditMode();
                          }
                        "
                        ><i class="fa fa-edit"></i> Uredi profil
                      </b-button>

<!--                      <b-button-->
<!--                        v-if="!editMode"-->
<!--                        variant="success"-->
<!--                        class="mx-1"-->
<!--                        @click="-->
<!--                          () => {-->
<!--                            showCertificateModal();-->
<!--                          }-->
<!--                        "-->
<!--                        ><i class="fas fa-certificate"></i> Potrdilo za popuste-->
<!--                      </b-button>-->
                    </b-col>
                    <b-col md="12">
                      <div v-if="editMode">
                        <label for="photos" class="btn btn-sm btn-primary text-white py-2 px-3 mt-3" style="opacity: 1;" v-if="!photos">
                          <i class="fa fa-image"></i>  Izberite sliko
                        </label>
                          <b-form-file
                              id="photos"
                            v-model="photos"
                            :directory="false"
                            :size="'lg'"
                            placeholder="Izberi slikovne datoteke ali jih povleci v to polje..."
                            drop-placeholder="Spusti datoteke..."
                            :multiple="false"
                            @change="previewImage"
                            accept="image/*"
                            style="display: none;"
                          ></b-form-file>

                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6" sm="12" xs="12" v-if="editMode">
                  <div>
                    <b-form class="form-horizontal contact-form">
                      <b-form-group>
                        <label for="firstName">Ime</label>
                        <b-input
                          v-model="user.firstName"
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="Ime"
                          required=""
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="lastName">Priimek</label>
                        <b-input
                          v-model="user.lastName"
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Priimek"
                          required=""
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="displayName"
                          >Prikazno ime oz. psevdonim (to ime bo vidno ob vaših
                          objavah)</label
                        >
                        <b-input
                          v-model="user.displayName"
                          type="text"
                          id="displayName"
                          name="displayName"
                          placeholder="Prikazno ime oz. psevdonim (to ime bo vidno ob vaših objavah)"
                          required=""
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="displayName"
                          >Nekaj besed o vas (ta opis bo viden v pogledu vseh vaših zapisov)</label
                        >
                        <b-textarea
                          v-model="user.bio"
                          id="bio"
                          name="bio"
                          placeholder="Vaš kratek opis (ta opis bo viden ob vaših objavah)"
                          required=""
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="yearOfBirth"
                          >Letnica rojstva (npr. 1970)</label
                        >
                        <b-input
                          v-model="user.yearOfBirth"
                          type="number"
                          step="1"
                          min="1900"
                          max="2022"
                          id="yearOfBirth"
                          name="yearOfBirth"
                          placeholder="Letnica rojstva (npr. 1970)"
                          required=""
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="phone"
                          >Telefonska številka (podatek ni obvezen)</label
                        >
                        <b-input
                          v-model="user.phone"
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="telefonska številka (podatek ni obvezen)"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="phone">E-poštni naslov</label>
                        <b-input
                          v-model="user.email"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="email naslov"
                          required=""
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label="Ali želite biti obveščeni o spremembi statusa vaših zapisov?"
                      >
                        <b-form-radio-group
                          id="btn-radios-1"
                          size="sm"
                          v-model="user.allowEmailNotifications"
                          :options="[
                            { text: 'DA, želim', value: true },
                            { text: 'NE, ne želim', value: false }
                          ]"
                          buttons
                        ></b-form-radio-group>
                      </b-form-group>

                      <b-button
                        type="submit"
                        class="send-btn mt-4"
                        variant="success"
                        @click.prevent.stop="updateUser"
                        ><i class="fa fa-save"></i> Shrani spremembe
                      </b-button>

                      <div class="mt-4">
                        <b-button
                          type="submit"
                          class="mt-4"
                          variant="link"
                          @click.prevent.stop="
                            () => {
                              user = $store.getters.getUser;
                              editMode = false;
                            }
                          "
                          >Prekliči urejanje in razveljavi spremembe
                        </b-button>

                        <hr class="mt-4"/>

                          <b-button
                            type="submit"
                            size="sm"
                            class="send-btn mt-2"
                            variant="danger"
                            @click.prevent.stop="beforeDeleteUser"
                          ><i class="fa fa-trash"></i> Deaktiviraj moj profil in izbriši vse moje osebne podatke
                          </b-button>


<!--                        {{ user }}-->
                      </div>
                    </b-form>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </article>
      </b-col>
    </b-row>

    <b-modal
      id="certificateModal"
      ref="certificateModal"
      hide-footer
      hide-header
    >
      <div class="certModalHeader text-center">
        <img
          :src="require('@/assets/images/Logo-glava.svg')"
          alt=""
          style="width: 90px; padding-top: 5px; margin-top: 5px;"
          class=""
        />
      </div>
      <div class="certModalBody">
        Potrjujemo, da je

        <div class="image">
          <b-img
            v-if="getUser && getUser.profileImg"
            :src="getUser && getUser.profileImg"
            rounded="circle"
            width="200"
            height="200"
            style="object-fit: cover;"
          />
          <b-img
            v-if="getUser && !getUser.profileImg"
            :src="require('@/assets/images/user-krogec-white.svg')"
            rounded="circle"
            width="200"
            height="200"
            style="object-fit: cover;"
          />
        </div>

        <div class="name mb-2">
          {{ getUser.firstName + " " + getUser.lastName }}
        </div>

        <div>
          registriran uporabnik oz. registrirana uporabnica aplikacije ZS.
        </div>

        <div class="mt-2">{{ formatPostsCount }}</div>

        <b-img
          class="mt-3"
          :src="require('@/assets/images/uporabnik-zig.svg')"
          rounded="circle"
          width="130"
          height="130"
        />
      </div>
    </b-modal>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";

import { db } from "@/main";
import _ from "lodash";
import firebase from "firebase";
import Loading from "vue-loading-overlay";

export default {
  name: "MyProfile",
  components: {
    Loading
  },
  data() {
    return {
      editMode: false,
      user: {
        id: null,
        uid: null,
        profileImg: null,
        firstName: null,
        lastName: null,
        displayName: null,
        yearOfBirth: null,
        phone: null,
        email: null,
        allowEmailNotifications: true,
        createdAt: null,
        updatedAt: null
      },
      photos: null,
      photoPreview: null,
      isLoading: false,
      postsCount: 0
    };
  },
  computed: {
    getUser() {
      console.log("getUser", this.$store.getters.getUser);
      return this.$store.getters.getUser;
    },

    formatPostsCount() {
      let strToReturn = "";
      if (this.postsCount === 0) {
        strToReturn = "Zaenkrat še ni prispeval/-a zapisov.";
      } else if (this.postsCount === 1) {
        strToReturn = "Prispeval/-a je že 1 zapis.";
      } else if (this.postsCount === 2) {
        strToReturn = "Prispeval/-a je že 2 zapisa.";
      } else if (this.postsCount === 3) {
        strToReturn = "Prispeval/-a je že 3 zapise.";
      } else if (this.postsCount >= 5) {
        strToReturn = "Prispeval/-a je že " + this.postsCount + " zapisov.";
      }
      return strToReturn;
    }
  },
  mounted() {
    this.user = _.cloneDeep(this.$store.getters.getUser);

    console.log("this.$route.params", this.$route.params);
    if (this.$route.params.showCert) {
      this.showCertificateModal();
    }
  },
  methods: {
    enableEditMode() {
      const _this = this;

      _this.user = _.cloneDeep(_this.$store.getters.getUser);
      _this.editMode = true;
    },
    async beforeDeleteUser() {
      const _this = this;

      this.$swal({
        title: "Ali ste prepričani, da želite deaktivirati vaš profil in izbrisati vse osebne podatke?",
        text:
          "Po potrditvi boste izpisani iz aplikacije, vsi vaši podatki pa bodo izbrisani.",
        icon: "error",
        type: "error",
        buttons: {
          cancel: {
            text: "Prekliči",
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Deaktiviraj moj profil in izbriši vse moje osebne podatke",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          }
        }
      }).then(value => {
        switch (value) {
          case true:
            _this.deleteUser();
            break;
        }
      });
    },
    async deleteUser() {
      const _this = this;
      console.log("delete user");

      const user = db.collection("users").doc(_this.user.id);

      return user
        .update({
          id: _this.user.id,
          uid: _this.user.uid,
          profileImg: null,
          firstName: '/',
          lastName: '/',
          displayName: '/',
          yearOfBirth: null,
          phone: null,
          email: null,
          allowEmailNotifications: false,
          updatedAt: new Date()
        })
        .then(function() {
          _this.signOut();
        })
        .catch(function(error) {
          _this.isLoading = false;
          console.error("Error adding Record: ", error);
        });
    },
    async updateUser() {
      const _this = this;

      window.scrollTo({ top: 0, behavior: "smooth" });
      _this.isLoading = true;

      if (_this.photos) {
        await _this.uploadImage(_this.photos);
      }

      const user = db.collection("users").doc(_this.user.id);

      return user
        .update({
          id: _this.user.id,
          uid: _this.user.uid,
          profileImg: _this.user.profileImg,
          firstName: _this.user.firstName,
          lastName: _this.user.lastName,
          displayName: _this.user.displayName,
          yearOfBirth: _this.user.yearOfBirth,
          phone: _this.user.phone,
          email: _this.user.email,
          allowEmailNotifications: _this.user.allowEmailNotifications,
          createdAt: _this.user.createdAt,
          updatedAt: new Date()
        })
        .then(function() {
          _this.$swal("Vaš profil je uspešno posodobljen!", "", "success");
          _this.$store.dispatch("setUser", {
            username: _this.user.email,
            displayName: _this.user.displayName
          });
          _this.editMode = false;
          _this.isLoading = false;
          _this.$forceUpdate();
        })
        .catch(function(error) {
          _this.isLoading = false;
          console.error("Error adding Record: ", error);
        });
    },

    previewImage(event) {
      const _this = this;

      let file = event.dataTransfer
        ? event.dataTransfer.files[0]
        : event.target.files[0];

      console.log("file", file);

      _this.photoPreview = URL.createObjectURL(file);
      _this.photos = file;
    },

    async uploadImage(photo) {
      const _this = this;

      if (!photo.name) {
        photo.name = _this.user.id + "-profileImage";
      }

      const storageRef = firebase
        .storage()
        .ref("userImages/" + _this.user.id)
        .child(photo.name)
        .put(photo);

      return new Promise((resolve, reject) => {
        storageRef.on(
          `state_changed`,
          snapshot => {
            _this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
            _this.$swal(
              "Pri shranjevanju vašega profile je prišlo do napake!",
              error.message,
              "danger"
            );
            reject(error);
          },
          () => {
            _this.uploadValue = 100;

            let name = photo.name.replace(/\.[^/.]+$/, "");
            let imgNameStr = name + "_400x400.jpeg";

            setTimeout(() => {
              const storageThumbnailRef = firebase
                .storage()
                .ref("userImages/" + _this.user.id)
                .child(imgNameStr);

              storageThumbnailRef.getDownloadURL().then(url => {
                _this.user.profileImg = url;
                resolve(url);
              });
            }, 3000);
          }
        );
      });
    },

    showCertificateModal() {
      const _this = this;
      _this.countPosts();
      _this.$refs["certificateModal"].show();
    },

    async countPosts() {
      const _this = this;

      let query = db.collection("posts");

      query = query.where("createdBy", "==", this.$store.getters.getUser.id);
      query = query.where("confirmed", "==", true);

      await query
        .get()
        .then(function(_docs) {
          _this.postsCount = _docs.docs.map(doc => doc.data()).length;
          console.log("_this.postsCount", _this.postsCount);
        })
        .catch(error => {
          console.log("Error:", error);
          _this.isLoading = false;
        });
    },

    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch("setUser", null);
          this.$router.push({
            name: "EN_SignIn"
          });
        });
    },
  }
};
</script>
